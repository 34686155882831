import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import TransitionLink from 'gatsby-plugin-transition-link';
import gsap, { Expo } from 'gsap';
import Layout from '../../components/layout';
import Transition from '../../components/transition';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { InitTransition } from '../../components/init-transition';
import InitAnimation from '../../components/init-animation';
import Style from '../../styles/works/work.module.scss';
import MainVisualSp from '../../images/works/food-design/main_visual_sp.jpg';
import MainVisualPc from '../../images/works/food-design/main_visual_pc.jpg';
import PublishedKyotoUniqlo from '../../components/published-kyoto-uniqlo';
import PublishedSeikaGenzine from '../../components/published-seika-genzine';

class Kex extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
    this.initAnimation = new InitAnimation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const img = document.querySelectorAll('.init_a_img');
    setTimeout(() => {
      gsap.to(img, {
        duration: 1.5,
        scale: 1,
        ease: Expo.easeOut,
      });
    }, 1000);
    const header = document.querySelector('header');
    const visual = document.querySelector('.visual');
    const visualHeight = visual.clientHeight - 64;
    window.addEventListener('scroll', () => { this.changeHeader(header, visualHeight); });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => { this.changeHeader(); });
  }

  changeHeader = (e, height) => {
    if (window.pageYOffset > height) {
      e.classList.add('work');
    } else {
      e.classList.remove('work');
    }
  };

  details = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const detailsItem = document.querySelectorAll('.init_a_detailsItem');
      this.initAnimation.list(detailsItem);
      unobserve();
    }
  };

  challengesPoint = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isChallengesPoint = 'ChallengesPoint';
      const headingChallengesPoint = document.querySelector('.init_a_headingChallengesPoint');
      const challengesPointItem = document.querySelectorAll('.init_a_challengesPointItem');
      this.initAnimation.heading(headingChallengesPoint, isChallengesPoint);
      this.initAnimation.list(challengesPointItem);
      unobserve();
    }
  };

  subCut1 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut1_bg');
      const subCutImg = document.querySelector('.init_a_subCut1_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  subCut2 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut2_bg');
      const subCutImg = document.querySelector('.init_a_subCut2_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  subCut3 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut3_bg');
      const subCutImg = document.querySelector('.init_a_subCut3_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  subCut4 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut4_bg');
      const subCutImg = document.querySelector('.init_a_subCut4_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  subCut5 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut5_bg');
      const subCutImg = document.querySelector('.init_a_subCut5_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  subCut6 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const subCutBg = document.querySelector('.init_a_subCut6_bg');
      const subCutImg = document.querySelector('.init_a_subCut6_img');
      this.initAnimation.slider(subCutBg, subCutImg);
      unobserve();
    }
  };

  approach = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isApproach = 'Approach';
      const headingApproach = document.querySelector('.init_a_headingApproach');
      const approachItem = document.querySelectorAll('.init_a_approachItem');
      this.initAnimation.heading(headingApproach, isApproach);
      this.initAnimation.list(approachItem);
      unobserve();
    }
  };

  projectTeam01 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isProjectTeam = 'ProjectTeam';
      const headingProjectTeam = document.querySelector('.init_a_headingProjectTeam');
      const projectTeamItem01 = document.querySelectorAll('.init_a_projectTeamItem01');
      this.initAnimation.heading(headingProjectTeam, isProjectTeam);
      this.initAnimation.list(projectTeamItem01);
      unobserve();
    }
  };

  projectTeam02 = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const projectTeamItem02 = document.querySelectorAll('.init_a_projectTeamItem02');
      this.initAnimation.list(projectTeamItem02);
      unobserve();
    }
  };

  otherWorks = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isOtherWorks = 'OtherWorks';
      const headingOtherWorks = document.querySelector('.init_a_headingOtherWorks');
      this.initAnimation.heading(headingOtherWorks, isOtherWorks);
      unobserve();
    }
  };

  render() {
    const WorkQuery = graphql`
      {
        DeviceSp: file(relativePath: { eq: "works/food-design/device_sp.png" }){
          childImageSharp {
            fluid(maxWidth: 170, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        DevicePc: file(relativePath: { eq: "works/food-design/device_pc.png" }){
          childImageSharp {
            fluid(maxWidth: 715, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut1: file(relativePath: { eq: "works/food-design/subcut_1.jpg" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut2: file(relativePath: { eq: "works/food-design/subcut_2.jpg" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut3: file(relativePath: { eq: "works/food-design/subcut_3.jpg" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut4: file(relativePath: { eq: "works/food-design/subcut_4.jpg" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut5: file(relativePath: { eq: "works/food-design/subcut_5.jpg" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        subCut6: file(relativePath: { eq: "works/food-design/subcut_6.jpg" }){
          childImageSharp {
            fluid(maxWidth: 960, quality: 90) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
      }
    `;

    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    const optionsDetails = {
      root: null,
      rootMargin: '-50% 0px',
      threshold: 0,
    };

    const options = {
      root: null,
      rootMargin: '-50px',
      threshold: 0,
    };

    return (
      <StaticQuery
        query={WorkQuery}
        render={(data) => (
          <Layout>
            <Transition ref={this.child}>
              <Header isWork transition={transition} current="works" />
              <main className={`work ${Style.item}`}>
                <div className={`visual ${Style.visual}`}>
                  <figure className={`init_a_visual ${Style.visualFigure}`}>
                    <img
                      className="spTabVDisBlock init_a_img"
                      src={MainVisualSp}
                      alt="food-design?"
                    />
                    <img
                      className="tabHPcDisBlock init_a_img"
                      src={MainVisualPc}
                      alt="food-design?"
                    />
                  </figure>
                  <div className={Style.visualDevice}>
                    <Img
                      className="spTabVDisBlock"
                      fluid={data.DeviceSp.childImageSharp.fluid}
                      alt="food-design?"
                    />
                    <Img
                      className="tabHPcDisBlock"
                      fluid={data.DevicePc.childImageSharp.fluid}
                      alt="food-design?"
                    />
                  </div>
                </div>
                <Observer {...optionsDetails} onChange={this.details}>
                  <section className={`details ${Style.details}`}>
                    <div className={`inner ${Style.inner}`}>
                      <div className={Style.block}>
                        <div className={Style.left}>
                          <p className={`init_a_detailsCategory_text ${Style.detailsCategory}`}>書籍編集制作</p>
                          <h1 className={`init_a_detailsTitle_text ${Style.detailsTitle}`}>
                          FOOD DESIGN<br/>フードデザイン 未来の食を探るデザインリサーチ
                          </h1>
                          <p className={`init_a_detailsCaption_text ${Style.detailsCaption}`}>京都工芸繊維大学 KYOTO Design Lab</p>
                          <p className={`init_a_detailsItem ${Style.description}`}>京都工芸繊維大学 KYOTO Design Labが行う「フード3Dプリンタを使った未来食の研究」の内容をベースにした書籍の編集制作を行いました。</p>
                          <p className={`init_a_detailsItem ${Style.link}`}>
                            <a
                              href="http://www.bnn.co.jp/books/11707/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              http://www.bnn.co.jp/books/11707/
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 17 14"
                              >
                                <rect x="4.5" y="0.5" width="12" height="9" />
                                <path d="M16,1v8H5V1H16 M17,0H4v10h13V0L17,0z" />
                                <rect x="0.5" y="4.5" width="12" height="9" />
                                <path d="M12,5v8H1V5H12 M13,4H0v10h13V4L13,4z" />
                              </svg>
                            </a>
                          </p>
                        </div>
                        <div className={Style.info}>
                          <dl className="init_a_detailsItem">
                            <dt>メディア</dt>
                            <dd>単行本</dd>
                          </dl>
                          <dl className="init_a_detailsItem">
                            <dt>プロジェクト期間</dt>
                            <dd>2019年11月〜2022年7月（研究報告書作成期間を含む）</dd>
                          </dl>
                          <dl className="init_a_detailsItem">
                            <dt>体裁</dt>
                            <dd>B5 208頁 オールカラー</dd>
                          </dl>
                          <dl className="init_a_detailsItem">
                            <dt>ソリューション</dt>
                            <dd>コンセプト設計／コンテンツ企画／情報設計／アートディレクション／デザイン制作／撮影ディレクション／コピーライティング／取材・原稿執筆／編集業務</dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </section>
                </Observer>
                <section className="sectionContent">
                  <div className="inner">
                    <Observer {...options} onChange={this.challengesPoint}>
                      <div>
                        <h2 className="heading">
                          <span className="text textChallengesPoint">Overview</span>
                          <span className="clip clipChallengesPoint init_a_headingChallengesPoint">Overview</span>
                        </h2>
                        <p className="init_a_challengesPointItem sectionAddition">概要</p>
                        <div className={`init_a_challengesPointItem ${Style.text}`}>
                          <p>KYOTO Design Labはデザインと建築を柱とする領域横断型の教育研究拠点として、京都工芸繊維大学内に設立された機関です。</p>
                          <p>ベースとなる研究内容をより分かりやすくすることはもちろん、書籍として網羅的かつ汎用性のある内容になることを念頭において、情報設計やコンテンツの企画を行いました。<br/>フードデザイン領域において、まだまだ成長段階であるという日本の現状に対し警鐘を鳴らすとともに、デザイン機関ならではのアプローチで実践知を授けることを目的としました。</p>
                        </div>
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.subCut1}>
                      <figure className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut1_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut1_img"
                          fluid={data.subCut1.childImageSharp.fluid}
                          alt="food-design?"
                        />
                      </figure>
                    </Observer>
                    <Observer {...options} onChange={this.subCut2}>
                      <div className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut2_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut2_img"
                          fluid={data.subCut2.childImageSharp.fluid}
                          alt="food-design?"
                        />
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.subCut3}>
                      <div className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut3_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut3_img"
                          fluid={data.subCut3.childImageSharp.fluid}
                          alt="food-design?"
                        />
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.subCut4}>
                      <div className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut4_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut4_img"
                          fluid={data.subCut4.childImageSharp.fluid}
                          alt="food-design?"
                        />
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.subCut5}>
                      <div className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut5_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut5_img"
                          fluid={data.subCut5.childImageSharp.fluid}
                          alt="food-design?"
                        />
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.subCut6}>
                      <div className={`init_a_subCut ${Style.slider}`}>
                        <div className="init_a_subCut_bg init_a_subCut6_bg" />
                        <Img
                          className="init_a_subCut_img init_a_subCut6_img"
                          fluid={data.subCut6.childImageSharp.fluid}
                          alt="food-design?"
                        />
                      </div>
                    </Observer>
                  </div>
                </section>

                <Observer {...options} onChange={this.approach}>
                  <section className="sectionContent">
                    <div className="inner">
                      <h2 className="heading">
                        <span className="text textApproach">Approach</span>
                        <span className="clip clipApproach init_a_headingApproach">Approach</span>
                      </h2>
                      <p className="init_a_approachItem sectionAddition">アプローチ</p>
                      <div className={`init_a_approachItem ${Style.text}`}>
                        <p>フードデザイン領域における著者の知見が大いに生かされ、その面白さがより際立つことを意識した、4つの章（01 フードデザインとは／02 フードデザインの現在／03 フードデザインの実践／04 フードデザインの未来）で構成を行いました。</p>
                        <p>各章では、国内外を対象としたフードデザインの流れの解説から、国内における先進事例の研究・分析、ケーススタディを用いた未来の食環境をデザインするための手法の紹介、共同クリエイターと実践した未来の食品開発レポートなどの内容を企画、制作しました。<br/>加えて、多様なジャンルの専門家らと未来の食をテーマに議論した座談会記事や、著者自らが2ヶ月183食フード3Dプリンタで生活した記録などもコラム的に制作し、「食のデザイン」に対して多様な視点や気づきを与える内容にしました。</p>
                      </div>
                    </div>
                  </section>
                </Observer>
                <section className={`sectionContent ${Style.projectTeam}`}>
                  <div className={`inner ${Style.inner}`}>
                    <Observer {...options} onChange={this.projectTeam01}>
                      <div>
                        <h2 className="heading">
                          <span className="text textProjectTeam">Project <br className="spDisBlock" />team</span>
                          <span className="clip clipProjectTeam init_a_headingProjectTeam">Project <br className="spDisBlock" />team</span>
                        </h2>
                        <p className="init_a_projectTeamItem01 sectionAddition">プロジェクトチームのご紹介</p>
                        <p className={`init_a_projectTeamItem01 ${Style.intro}`}>
                          競争から共創へ。
                          <br />
                          弊社では、クライアントと
                          <br className="spDisBlock" />
                          受託会社、制作会社と
                          <br />
                          外部パートナーといった
                          <br className="spDisBlock" />
                          垣根を越え、
                          <br />
                          プロジェクトごとに最適な
                          <br className="spDisBlock" />
                          チームを編成しています。
                        </p>
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.projectTeam02}>
                      <div>
                        <div className={Style.credit}>
                          <h3 className="init_a_projectTeamItem02">プロジェクト体制</h3>
                          <div className="init_a_projectTeamItem02">
                            <dl>
                              <dt>光川 貴浩</dt>
                              <dd>Strategy／Contents</dd>
                            </dl>
                            <dl>
                              <dt>早志 祐美</dt>
                              <dd>Strategy／Contents</dd>
                            </dl>
                            <dl>
                              <dt>吉田 健人</dt>
                              <dd>Design</dd>
                            </dl>
                          </div>
                        </div>
                        <div className={Style.credit}>
                          <h3 className="init_a_projectTeamItem02">外部パートナー</h3>
                          <dl className="init_a_projectTeamItem02">
                            <dt>飯田 久美子</dt>
                          </dl>
                          <dl className="init_a_projectTeamItem02">
                            <dt>INFOBAHN DESIGN LAB. 遠藤 英之</dt>
                          </dl>
                          <dl className="init_a_projectTeamItem02">
                            <dt>岡田 芳枝</dt>
                          </dl>
                          <dl className="init_a_projectTeamItem02">
                            <dt>平田 由布子</dt>
                          </dl>
                          <dl className="init_a_projectTeamItem02">
                            <dt>カワイハルナ（イラストレーター）</dt>
                          </dl>
                          <dl className="init_a_projectTeamItem02">
                            <dt>岡本 佳樹（カメラマン）</dt>
                          </dl>
                          <dl className="init_a_projectTeamItem02">
                            <dt>原 祥子（カメラマン）</dt>
                          </dl>
                        </div>
                        <div className={Style.credit}>
                          <h3 className="init_a_projectTeamItem02">クライアント</h3>
                          <p className={`init_a_projectTeamItem02 ${Style.client}`}>京都工芸繊維大学 KYOTO Design Lab</p>
                        </div>
                      </div>
                    </Observer>
                  </div>
                </section>
                <Observer {...options} onChange={this.otherWorks}>
                  <section className={`sectionContent ${Style.otherWorks}`}>
                    <h2 className="heading">
                      <span className="text textOtherWorks">Other <br className="spDisBlock" />works</span>
                      <span className="clip clipOtherWorks init_a_headingOtherWorks">Other <br className="spDisBlock" />works</span>
                    </h2>
                    <PublishedKyotoUniqlo transition={transition} />
                    <PublishedSeikaGenzine transition={transition} />
                    <p className={`btn ${Style.btn}`}>
                      <TransitionLink to="/works" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                        <span>Explore our works</span>
                      </TransitionLink>
                    </p>
                  </section>
                </Observer>
              </main>
              <Footer transition={transition} />
            </Transition>
          </Layout>
        )}
      />
    );
  }
}

export default Kex;
